import Link from 'next/link'
import styles from './ProjectCard.module.scss'
import { ClassNames } from '@/services/classname.service'

const ProjectCard = ({ className = '', project = {} }) => {
  return (
    <article className={styles['project-card']}>
      {project?.gridImage && (
        <Link href={`/projects/${project?.id}`}>
          <picture>
            <img src={project?.gridImage} />
          </picture>
        </Link>
      )}

      <div className={ClassNames([styles.text, 'mt-12'])}>
        <h3>{project?.category}</h3>
        <h2 className="small:pl-20 pl-0">{project?.title}</h2>{' '}
        <span className="description">{project?.shortDescription}</span>
      </div>
    </article>
  )
}

ProjectCard.propTypes = {}

export default ProjectCard
