import styles from './Grid.module.scss'
import { ClassNames } from '@/services/classname.service'

interface IGridProps {
  className?: string
  gridSize?: '2' | '3' | '4' | '5' | '6'
  narrow?: boolean
  children?: React.ReactNode
}

const Grid = ({ className = '', gridSize = '2', narrow = false, children }: IGridProps) => {
  return (
    <section
      className={ClassNames([
        styles.grid,
        styles[`grid--${gridSize}`],
        className,
        narrow ? styles['grid--narrow'] : null
      ])}
    >
      {children}
    </section>
  )
}

export default Grid
