'use client'

import HomeHero from '@/components/HomeHero'
import ProjectGrid from '@/components/ProjectGrid'
import PageLayout from '@/layouts/PageLayout/PageLayout'
import { ProjectData } from '@/data/projects'
import { useState } from 'react'
import Spacer from '@/components/Spacer'
import Grid from '@/layouts/Grid'
export default function Home({  }) {
  const projects = ProjectData?.projects || []
  const [color, setColor] = useState()
  return (
    <PageLayout title="Home" onColorChange={(color) => setColor(color)}>
      <HomeHero color={color} />

      <section className="accordion" id="mySwipe">
        <ul className="swipe-wrap">
          <li className="open fade" style={{ backgroundImage: 'url(/ss-hero-neu-1-lg.jpg)' }}>
            <a href="/projects/story-snap">
              <div className="content c0">
                <p className="number">.00</p>
                <h2>StorySnap</h2>
                <div className="divider"></div>
                <p>Sharing personalized stories</p>
                <img src="/go-btn.svg" width="48" className="go" />
              </div>
            </a>
          </li>
          <li className="open fade" style={{ backgroundImage: 'url(/montour-hero-neu-3-lg.jpg)' }}>
            <a href="/projects/montour">
              <div className="content c2">
                <p className="number">.02</p>
                <h2>Montour</h2>
                <div className="divider"></div>
                <p>Cycling App</p>
                <img src="/go-btn.svg" width="48" className="go" />
              </div>
            </a>
          </li>
          <li className="open fade" style={{ backgroundImage: 'url(/ahk-hero-neu-lg.jpg)' }}>
            <a href="/projects/acid-house-kings">
              <div className="content c3">
                <p className="number">.03</p>
                <h2>Acid House Kings</h2>
                <div className="divider"></div>
                <p>iPad Album</p>
                <img src="/go-btn.svg" width="48" className="go" />
              </div>
            </a>
          </li>
        </ul>
        <div className="featured-text">
          <div className="inner">
            Featured
            <br />
            Projects
            <br />
            Showcase
          </div>
        </div>
      </section>

      <Spacer />

      <Grid gridSize="1" narrow={true}>
        <div>
          <hr className="hr thick" />
          <p>
            Wtf does that mean? It means we are different. Very different. We don’t follow trends,
            and we have no desire to make them. We are simply creators. Craftspeople. Our canvas of
            choice is digital — iOS, Android, www and whatever comes next — and our galleries are
            the internet, mobile and social applications. With that said, our roots are in art;
            design, illustration, animation and film, consequently we create in digital languages
            with analog sensitivity. We are functioning artoholics.
          </p>

          <p>
            Every co-conspirator we work with wants the same thing, to be different, to stand out,
            to not be a sheep — unless it is black. They care deeply about aesthetics, creativity
            and high level functions, and they want it to be flexible and super simple to use. We
            have an app for that. (Sorry.)
          </p>
        </div>
      </Grid>

      <Spacer />

      <ProjectGrid projects={projects} />

      <Spacer />
    </PageLayout>
  )
}
