import shortid from 'shortid'
import styles from './ProjectGrid.module.scss'
import ProjectCard from '@/components/ProjectCard'

const ProjectGrid = ({className='', projects=[]}) => {
  return <section className={styles['project-grid']}>
    {projects.map(project => <ProjectCard key={shortid.generate()} project={project || {}} />)}
  </section>
}

export default ProjectGrid