'use client'

import styles from './HomeHero.module.scss'

const GetDate = () => {
  const date = new Date()
  const month = date.getMonth() > 10 ? date.getMonth() : `0${date.getMonth()}`;
  const day = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`;
  return `${month} ${day} ${date.getFullYear()}`
}

const HomeHero = ({className=''}) => {

  return (
    <div id="intro-container">
      <section id="intro">
        <div className="text">
          <div className="lower">
            <hr className="hr animate hr-slide-in" />
            <h3>A Digital Conspiracy</h3>
            <img src="squiggles.svg" width="30" className="squiggles" />
            <div className="content">
              <p>
                Lackner Buckingham was a small footprint{" "}
                <span className="firm-break"></span>
                <span className="inline">
                  <svg
                    id="trans-line"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 156.6 11.3"
                  >
                    <path
                      d="M233.9,400.1l-3.9-3.2c1.8-1.1,4-1.2,6-1.3q74-3.9,148-4.1"
                      transform="translate(-227.4 -390)"
                      id="trans-line-path"
                    ></path>
                  </svg>
                  transdisciplinary
                </span>
                ,{" "}
                <span className="inline">
                  <svg
                    id="firm-line"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 44.6 4.4"
                  >
                    <title>firm</title>
                    <path
                      id="firm-line-path"
                      d="M284,397l44.5-1.5"
                      transform="translate(-284 -394)"
                    ></path>
                  </svg>
                  firm
                </span>{" "}
                <span className="ipad-break"></span>,{" "}
                <span className="inline">
                  <svg
                    id="anti"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.7 80.6"
                  >
                    <path
                      id="anti-arrow"
                      d="M283.8,431.9,301.9,403c-1.1,11.3-2.3,22.6-3.4,34"
                      transform="translate(-278.6 -357.8)"
                    ></path>
                    <path
                      id="anti-i"
                      d="M330.9,365l.9,14.5"
                      transform="translate(-278.6 -357.8)"
                    ></path>
                    <path
                      id="anti-dot"
                      d="M332,353"
                      transform="translate(-278.6 -357.8)"
                    ></path>
                    <path
                      id="anti-a"
                      d="M283.7,376.5a98.5,98.5,0,0,1-3.6,26.5l9.2-40.6a60.1,60.1,0,0,1,5.6,30.7c-.1,1-.6,2.4-1.6,2.3a1.8,1.8,0,0,1-1.3-.9l-4.1-4.6c-1-1.2-2.1-2.6-1.8-4.1s1.6-2.3,2.7-3l14.3-8.9"
                      transform="translate(-278.6 -357.8)"
                    ></path>
                    <path
                      id="anti-n"
                      d="M306,380.5a66.6,66.6,0,0,1-1.2,7.6l-1-12.5a33.5,33.5,0,0,0,7.8,7.7,144.2,144.2,0,0,0,.3-14.7"
                      transform="translate(-278.6 -357.8)"
                    ></path>
                    <path
                      id="anti-cross"
                      d="M317.1,369.7l9.7-5.6"
                      transform="translate(-278.6 -351.5)"
                    ></path>
                    <path
                      id="anti-t"
                      d="M321.6,361.4l.7,22.3"
                      transform="translate(-278.6 -357.8)"
                    ></path>
                  </svg>
                </span>
                <span className="inline">
                  <svg
                    id="anti-x"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 116.2 34.3"
                  >
                    <path
                      id="anti-x-1"
                      d="M248.6,404.4a414.9,414.9,0,0,1,99.3-24"
                      transform="translate(-248.1 -378.9)"
                    ></path>
                    <path
                      id="anti-x-2"
                      d="M264,382.5a247.6,247.6,0,0,1,99.6,29.4"
                      transform="translate(-248.1 -378.9)"
                    ></path>
                  </svg>
                  agency
                </span>
                ,{" "}
                <span className="inline">
                  <svg
                    id="studio"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 77.4 10.5"
                  >
                    <path
                      id="studio-path"
                      d="M277.7,399.1a13.4,13.4,0,0,1-6.4-2.3l73-5.1"
                      transform="translate(-266.9 -390.2)"
                    ></path>
                  </svg>
                  studio
                </span>
                , <span className="iphone-break"></span>
                <span className="inline">
                  <svg
                    id="group"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 83.7 8"
                  >
                    <path
                      id="group-path"
                      d="M264,398.8l83.5-5"
                      transform="translate(-263.9 -392.3)"
                    ></path>
                  </svg>
                  group
                </span>
                , developer
                <span className="inline">
                  <svg
                    id="squiggle-circle"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 166.9 45.2"
                  >
                    <path
                      d="M301.5,408.9a335.8,335.8,0,0,1-65.4-9.3c-4.6-1.1-10-3-11.5-7.5a3.1,3.1,0,0,1,0-2.8,4.8,4.8,0,0,1,2.7-1.9c30.2-10.7,62.8-11.9,94.8-12.6,19.2-.5,38.4-.8,57.4,1,3.6.3,8,1.5,8.6,5,.4,1.9-.7,3.9-1.9,5.4-4.5,5.6-11.7,8.3-18.5,10.5-26.8,8.6-54.6,13.8-82.4,18.9a30.1,30.1,0,0,1,14.1-6.6l-6.9.3"
                      transform="translate(-222.8 -372.9)"
                      id="squiggle-circle-path"
                    ></path>
                  </svg>
                  ... conspiracy
                </span>
                . We still create fully bespoke, aesthetically refined, design
                literate, user friendly, handcrafted digital experiences for
                web, iOS and Android, but we just got a bigger tool box. Lackner Buckingham has joined forces with <a href="https://planetpropaganda.com">Planet Propaganda</a> to radically elevate our digital services. Together we are Internationally recognized strategists, consultants, creative technologists, designers, developers, producers, filmmakers, marketers and super badass problem solvers. We have conspired to create rich multi-platform experiences by continuing to push the boundaries of digital languages with analog sensitivity.
              </p>
            </div>
          </div>
        </div>
        <div className="date">
          {/* <span id="year_day"># 14 23 32</span>{" "} | */}
          <span id="month"> {GetDate()}</span>
        </div>
      </section>
    </div>
  );
}

HomeHero.propTypes = {}

export default HomeHero
